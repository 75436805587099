import bg from "./assets/background.webp";
import artur from "./assets/team/artur.webp";
import david from "./assets/team/david.webp";
import dominik from "./assets/team/dominik.webp";
import hubert from "./assets/team/hubert.webp";
import jas from "./assets/team/jas.webp";
import karina from "./assets/team/karina.webp";
import lizur from "./assets/team/lizur.webp";
import marta from "./assets/team/marta.webp";
import pjanek from "./assets/team/pjanek.webp";

import { ReactComponent as NsaLogo } from "./assets/nsa.svg";
import fog from "./assets/fog.webp";
import { ReactComponent as SpotifyIcon } from "./assets/social-media/spotify.svg";
import { ReactComponent as YoutubeIcon } from "./assets/social-media/youtube.svg";
import { ReactComponent as FacebookIcon } from "./assets/social-media/facebook.svg";
import { ReactComponent as InstagramIcon } from "./assets/social-media/instagram.svg";

import TeamImage from "./components/TeamImage";
import SocialIcon from "./components/SocialIcon";
import React, { Suspense, lazy } from "react";
import ContactSection from "./components/sections/ContactSection";
import DownloadSection from "./components/sections/DownloadSection";

function App() {
  // const Hubert = lazy(() => import("./components/team/Hubert"));
  // const David = lazy(() => import("./components/team/David"));
  // const Szymon = lazy(() => import("./components/team/Szymon"));
  // const Marta = lazy(() => import("./components/team/Marta"));
  // const Artur = lazy(() => import("./components/team/Artur"));
  // const Karina = lazy(() => import("./components/team/Karina"));
  // const Janek = lazy(() => import("./components/team/Janek"));
  // const Dominik = lazy(() => import("./components/team/Dominik"));
  // const Jasiu = lazy(() => import("./components/team/Jasiu"));

  return (
    <Suspense
      fallback={() => {
        <></>;
      }}
    >
      <div className="relative font-archivio text-xl lg:text-2xl 2xl:text-4xl">
        <div
          className="bg-local bg-cover bg-no-repeat h-full w-full opacity-50 absolute z-20"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <div className="flex flex-col flex-nowrap min-h-screen">
          <div className="grow h-auto flex flex-col justify-evenly">
            {/* Top images */}
            <div className="relative flex justify-center mt-[3.25rem]">
              <img
                src={fog}
                alt=""
                className="object-cover object-top absolute top-[calc(100vw*0.0885)] max-h-[46.55rem] xl:top-[7rem] z-40 mx-auto"
                // loading={"lazy"}
              />
              <NsaLogo className="absolute z-[45] bottom-0 h-[calc(100vw*0.151)] xl:h-[12.125rem] drop-shadow-[0_18px_47px_rgba(0,0,0,1)] animate-[fade-in_1s_ease-in-out_1s_1_both]" />
              <div className="h-[calc(100vw*0.295)] xl:h-[23.6rem] w-auto flex justify-center">
                {/*h-[8rem] sm:h-[10rem] md:h-[15rem] lg:h-[20rem]*/}
                {/*<Hubert />*/}
                {/*<David />*/}
                {/*<Szymon />*/}
                {/*<Marta />*/}
                {/*<Artur />*/}
                {/*<Karina />*/}
                {/*<Janek />*/}
                {/*<Dominik />*/}
                {/*<Jasiu />*/}
                <TeamImage
                  src={hubert}
                  alt="Hubert"
                  className="z-[31] right-[199%] animate-slide-in-left"
                />
                <TeamImage
                  src={david}
                  alt="David"
                  className="z-[36] right-[130%] animate-slide-in-left"
                />
                <TeamImage
                  src={lizur}
                  alt="Szymon"
                  className="z-[32] right-[104%] animate-slide-in-left"
                />
                <TeamImage
                  src={marta}
                  alt="Marta"
                  className="z-[37] right-[46%] animate-slide-in-left"
                />
                <TeamImage
                  src={artur}
                  alt="Artur"
                  className="z-[33] left-[15%] animate-slide-in-right"
                />
                <TeamImage
                  src={karina}
                  alt="Karina"
                  className="z-[38] left-[60%] animate-slide-in-right"
                />
                <TeamImage
                  src={pjanek}
                  alt="Jan"
                  className="z-[34] left-[104%] animate-slide-in-right"
                />
                <TeamImage
                  src={dominik}
                  alt="Dominik"
                  className="z-[39] left-[153%] animate-slide-in-right"
                />
                <TeamImage
                  src={jas}
                  alt="Jan"
                  className="z-[35] left-[227%] animate-slide-in-right"
                />
              </div>
            </div>
            {/*Social media links */}
            <div className="relative z-50 flex gap-x-0 md:gap-x-[calc(100vw/10)] xl:gap-x-36 md:mx-auto justify-evenly ">
              <SocialIcon href="https://open.spotify.com/artist/5IDneqptEA1VXBFIrXkSrb?si=8dRdcBfUSG6ih8TvW6NTuw">
                <SpotifyIcon className="p-2 h-16 w-auto md:h-20" />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/@nsa_zespol">
                <YoutubeIcon className="p-2 h-16 w-auto md:h-20" />
              </SocialIcon>
              <SocialIcon href="https://www.facebook.com/zespolnsa">
                <FacebookIcon className="p-2 h-16 w-auto md:h-20" />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/nsa_zespol/">
                <InstagramIcon className="p-2 h-16 w-auto md:h-20" />
              </SocialIcon>
            </div>
          </div>
          {/*Bottom section*/}
          <div className="flex-none bg-gradient-to-r from-shark-950 to-jungle-green-500 text-white">
            <div className="z-50 relative flex flex-row justify-start w-fit mx-auto">
              {/* Download section */}
              <DownloadSection />
              {/* Contact section */}
              <ContactSection className="hidden lg:flex" />
            </div>
          </div>
          <div className="flex-none lg:hidden z-[55] text-black">
            {/* Contact section */}
            <ContactSection className="flex" />
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
