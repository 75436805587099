import React from "react";
import contactHeader from "../../assets/headers/contact-header.webp";
import pawel from "../../assets/team/pawel.webp";
import Link from "../Link";

const ContactSection = ({ className }) => {
  return (
    <div
      className={`flex flex-col justify-start items-center mx-8 ${className}`}
    >
      <img
        src={contactHeader}
        alt="Kontakt"
        className="object-contain h-[3.6106rem] lg:h-[4.9646rem] 2xl:h-[6.375rem] w-auto relative -top-4 lg:-top-5 2xl:-top-7"
      />
      <div className="flex flex-row items-center relative h-[10rem] sm:h-[14rem] 2xl:h-[15rem] bottom-6 2xl:bottom-0 -left-14 sm:-left-12 lg:-left-8 2xl:left-0">
        <img
          src={pawel}
          alt="Pawel"
          className="relative 2xl:-bottom-2 h-[13rem] sm:h-[17rem] 2xl:h-[23.9375rem] sm:-left-2"
        />
        <div className="mt-12">
          <p className="text-base 2xl:text-2xl">Menager zespołu</p>
          <p>Paweł Gogola</p>
          <p className="mt-4">
            <Link href="tel:603940813">603 940 813</Link>
          </p>
          <p>
            <Link href="mailto:kontakt@zespolnsa.pl">kontakt@zespolnsa.pl</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
